<template>
    <div class="view pa24">
      <el-form :model="serchParams" class="demo-form-inline" :inline="true">
            <el-row style="padding-top: 20px;">
                <!-- <el-form-item label="类别" label-position="left" class="fromItem" prop="classId">
                  <el-cascader
                  v-model="serchParams.classId"
                  :options="classOptions"
                  :props="{
                      checkStrictly: true,
                      label:'name',
                      children: 'children',
                      value: 'id',
                      emitPath:false
                  }"
                  @change="classIdChange">
                 </el-cascader>
                </el-form-item> -->
                <el-form-item v-if="labelName" :label="labelName" label-width="100px" label-position="left" class="fromItem" prop="dealerId">
                <el-select v-model="serchParams.dealerId" placeholder="请选择">
                  <el-option
                    v-for="item in dealerOptions"
                    :key="item.id"
                    :label="item.dealerName"
                    :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>

                <el-form-item label="操作时间" label-width="100px" label-position="left" class="fromItem" prop="startTime">
                  <el-date-picker
                    v-model="serchParams.timeRange"
                    type="daterange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    value-format="yyyy-MM-dd"
                    >
                  </el-date-picker>
                </el-form-item>
                <!-- <el-form-item label="结束时间" label-width="100px" label-position="left" class="fromItem" prop="endTime">

                </el-form-item> -->
                <!-- <el-form-item label="库房" label-width="100px" label-position="left" class="fromItem" prop="warehouseId">
                  <el-select v-model="serchParams.warehouseId" placeholder="请选择">
                    <el-option
                      v-for="item in storeOptions"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id">
                    </el-option>
                  </el-select>
                </el-form-item> -->
                <el-button type="primary" @click="search">搜索</el-button>
                <el-button @click="resetSearch">重置</el-button>
            </el-row>
        </el-form>
      <commonTable
        :tableData="tableData"
        :total="total"
        :currentPage="pageNum"
        @handleSizeChange="handleSizeChange"
        @handleCurrentChange="handleCurrentChange"
      >
        <template v-slot:table>

          <!-- <el-table-column
              prop="classId"
              align="center"
              label="类别"
          /> -->
          <el-table-column
            prop="lotNumber"
            align="center"
            label="批次号"
                width="360"
          />
          <el-table-column
              prop="packNum"
              align="center"
              label="数量"
          >
            <template slot-scope="scope">
              {{scope.row.boxNum||0}}箱 + {{scope.row.packNum||0}}盒
            </template>
          </el-table-column>
          <!-- <el-table-column
              prop="warehouseName"
              align="center"
              label="仓库"
          /> -->
          <el-table-column
              prop="name"
              align="center"
              label="操作人"
          />

            <!-- <template slot-scope="scope">
              {{scope.row.handle}}
              <span :class="scope.row.type == 3?'name_status':'name_status1'">{{scope.row.type == 3 ? '箱' : '盒'}}</span>
              <span class="copyTxt" @click="copy(scope.row.handle)"><img src="../../assets/images/fz.png" /></span>
            </template> -->

          <el-table-column
              prop="createTime"
              align="center"
              label="退货时间"
          />
          <el-table-column
              prop="remarks"
              align="center"
              label="备注"
          />
          <el-table-column align="center" width="200" label="操作">
            <template slot-scope="scope">
              <el-button type="text" v-if="scope.row.revokeStatus == 0" @click="revoke(scope.row)">撤销</el-button>
              <el-button type="text" @click="goDetail(scope.row)">查看</el-button>
            </template>
          </el-table-column>
        </template>
      </commonTable>

  <detail ref="boxDetail"></detail>
    </div>
  </template>
  <script>
  import commonTable from "@/components/common/commonTable";
  import { warehouseLogPage,warehouseLogDetail, fetchCrkBatchList, fetchRevoke, fetchCrkBackList } from '@/api/warehouseManage.js'
  import { pageClassList } from '@/api/customPageClass.js'
  import { fetchDealList } from '@/api/companyManage.js'
  import { selectWarehouseList } from '@/api/warehouseManage.js'
  import detail from "./detail.vue";
  
  export default {
    name: "dealerList",
    components: {
      commonTable,detail
    },
    data() {
      return {
          tableData: [],
          pageSize: 10,
          pageNum: 1,
          total: 0,
          wareStatus: 1,
          serchParams: {
            classId: '',
            dealerId:'',
            timeRange: [],
            warehouseId:''
          },
          classOptions:[],
          dealerOptions:[],
          storeOptions:[],
          labelName: '',
      };
    },
    created() {
      // 获取所有分类
      this.getClassList()
      // 获取所有代理商、经销商列表
      this.getDealerList()
      // 获取所有仓库列表
    //   this.getStoreList()
      this.getList()
      let userInfo = JSON.parse(localStorage.getItem('info'));
      let roleType = userInfo.userType
      if(roleType == 3) { // 代理商,获取经销商列表
        this.labelName='经销商'
      }else if(roleType == 4) { // 经销商

      }else {
        this.labelName='代理商'
      }
    },
    methods: {
      classIdChange(){

      },
        copy(context) {
                    // 创建输入框元素
                    let oInput = document.createElement('input');
                    // 将想要复制的值
                    oInput.value = context;
                    // 页面底部追加输入框
                    document.body.appendChild(oInput);
                    // 选中输入框
                    oInput.select();
                    // 执行浏览器复制命令
                    document.execCommand('Copy');
                    // 弹出复制成功信息
                    this.$message.success('复制成功');
                    // 复制后移除输入框
                    oInput.remove();
                },
        handleSizeChange(num) {
          this.pageSize = num;
          this.pageNum = 1;
          this.getList();
        },
        handleCurrentChange(num) {
          this.pageNum = num;
          this.getList();
        },
        handleAddDealer(type, id) {
            this.$refs.addDealerDialog.openDialog(type, id)
        },
        // 过滤掉空的children
        formatData(data) {
          for (var i = 0; i < data.length; i++) {
            if (data[i].children == null || data[i].children.length == 0) {
              data[i].children = undefined;
            } else if (data[i].children.length > 1) {
              this.formatData(data[i].children);
            }
          }
          return data;
        },
        getClassList() {
          let params={
            pageNum: 1,
            pageSize: 999,
            type:0
          }
          pageClassList(params).then(res => {
            this.classOptions = this.formatData(res.data.list)
          })
        },
        getStoreList() {
          let params={
            pageNum: 1,
            pageSize: 999,
            companyId: JSON.parse(localStorage.getItem('info')).companyId,
          }
          selectWarehouseList(params).then(res => {
            this.storeOptions = res.data.list
          })
        },
        getDealerList() {
          let userInfo = JSON.parse(localStorage.getItem('info'));
          let roleType = userInfo.userType
          if(roleType == 3) {  // 代理商,获取经销商列表
            let params={
              pageNum: 1,
              pageSize: 999,
              type:2,
              companyId: JSON.parse(localStorage.getItem('info')).companyId,
            }
            fetchDealList(params).then(res => {
              this.dealerOptions = res.data.list
            })
          }else if(roleType == 4) { // 经销商

          }else { // 厂家，获取代理商
            let params={
              pageNum: 1,
              pageSize: 999,
              type:1,
              companyId: JSON.parse(localStorage.getItem('info')).companyId,
            }
            fetchDealList(params).then(res => {
              this.dealerOptions = res.data.list
            })
          }
        },
        search() {
          this.pageNum = 1
          this.getList()
        },
        resetSearch() {
          this.serchParams.classId = ''
          this.serchParams.dealerId = ''
          this.serchParams.timeRange = []
        //   this.serchParams.warehouseId = ''
          this.pageNum = 1
          this.getList()
        },
        getList() {
          let params={
            pageNum: this.pageNum,
            pageSize: this.pageSize,
            // wareStatus:this.wareStatus,
            classId: this.serchParams.classId,
            dealerId: this.serchParams.dealerId,
            startTime: this.serchParams.timeRange && this.serchParams.timeRange.length > 0 ? this.serchParams.timeRange[0] : '',
            endTime: this.serchParams.timeRange && this.serchParams.timeRange.length > 0 ? this.serchParams.timeRange[1] : '',
            // warehouseId: this.serchParams.warehouseId
          }
          fetchCrkBackList(params).then(res => {
            this.tableData = res.data.list
            this.total = res.data.total
          })
          // warehouseLogPage(params).then(res => {
          //   this.tableData = res.data.list
          //   this.total = res.data.total
          // })
        },
        revoke(row) {
        console.log(row.wareStatus)
          this.$confirm('此操作将撤销'+(+row.wareStatus===1?'入库':'出库')+', 是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            fetchRevoke({batchId: row.id}).then(res => {
              if(res.code === 200) {
                this.getList()
                this.$message({
                  type: 'success',
                  message: '撤销成功!'
                });
              }
            })

          }).catch(() => {

          });

        },
        goDetail(row) {
          this.$router.push({path:'/crk/warehouseLog/backDetail',query: {id:row.id, name:row.name, labelName: this.labelName, boxNum: row.boxNum, packNum: row.packNum, createTime: row.createTime}})
        },
        boxDetail(data){
          this.$refs.boxDetail.init(data.id,this.wareStatus)
          this.$refs.boxDetail.dialogTableVisible= true
       }
    }
  }
  </script>

  <style lang="scss" scoped>
      .name_status {
          display: inline-block;
          width: 26px;
          height: 17px;
          line-height: 15px;
          background: rgba(63, 114, 246, 0.11);
          border: 1px solid #3F72F6;
          border-radius: 4px;
          font-size: 12px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #3F72F6;
          text-align: center;
      }

      .name_status1 {
          display: inline-block;
          width: 26px;
          height: 17px;
          line-height: 15px;
          background: rgba(243, 136, 46, 0.11);
          border: 1px solid #F3882E;
          border-radius: 4px;
          font-size: 12px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #F3882E;
          text-align: center;
      }

      .copyTxt {
          display: inline-block;
          margin-left: 5px;
          cursor: pointer;
          color: #3F72F6;
      }
  </style>

